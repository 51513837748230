import { MenuGroup, MenuItem } from "types";

function createSection(group: MenuGroup): HTMLElement {
  const newSection = document.createElement('section')
  newSection.setAttribute('class', 'section');
  newSection.innerHTML = `<div class="section__title">
          <h2>${group.groupName}</h2>
        </div>        
        ${group.groupDescription !== "" ? `<div class="section__extra">
          ${group.groupDescription}
        </div>` : ""}
        <div class="section-menu" id="${group.groupName}">
          ${group.items.map((item: MenuItem) => {
    return `<div class="section-menu__submenu" id="${item.itemId}" name="${group.groupName}-product">
                        <div class="section-menu__title">
                          ${item.itemName}
                        </div>
                        <div class="section-menu__item">
                          <span class="name">
                            ${item.itemDescription}
                          </span>
                            <span class="price">
                              ${parseFloat(item.price.toString().replace(",", ".")) > 0 ? item.price : ""}
                            </span>
                        </div>
                      </div>`
  }
  )}
        </div>`
  return newSection
}

async function fetchGroupInfo(itemId: string, locale: string): Promise<Object | void> {
  const storeAbbreviation = document.getElementById('store-id').innerText;
  var url = `/get-group-info/${locale}/${itemId}/${storeAbbreviation}`;
  var request = {
    method: "GET",
  };
  var returnSection = document.createElement('section')
  const response = await fetch(url, request);
  const result = await response.json()
  const newGroup: MenuGroup = await result["menuGroup"];
  returnSection = createSection(newGroup);
  return { sortNo: newGroup.groupSortNo, element: returnSection };
}

export function removeItem(itemId: string, storeAbbreviation: string) {
  if (document.getElementById('store-id').innerText === storeAbbreviation) {
    const item = document.getElementById(itemId);
    if (item) {
      const parent = item.parentElement;
      item.remove();
      if (parent.childElementCount === 0) {
        parent.parentElement.remove()
      }
    }
  }
}

export async function addItem(itemNl: MenuItem, itemEn: MenuItem, storeAbbreviation: string) {
  if (document.getElementById('store-id').innerText === storeAbbreviation) {
    const newMenuItem = document.createElement('div');
    newMenuItem.setAttribute('class', 'section-menu__submenu');
    newMenuItem.setAttribute('id', itemNl.itemId);
    newMenuItem.setAttribute('name', itemNl.groupName + "-product");
    newMenuItem.innerHTML =
      `<div class="section-menu__title">
        ${itemNl.itemName}
      </div>
      <div class="section-menu__item">
        <span class="name">
          ${itemNl.itemDescription}
        </span>
        <span class="price">
          ${itemNl.price}
        </span>
      </div>`;
    const newMenuItemEn = document.createElement('div');
    newMenuItemEn.setAttribute('class', 'section-menu__submenu');
    newMenuItemEn.setAttribute('id', itemEn.itemId);
    newMenuItemEn.setAttribute('name', itemEn.groupName + "-product");
    newMenuItemEn.innerHTML =
      `<div class="section-menu__title">
        ${itemEn.itemName}
      </div>
      <div class="section-menu__item">
        <span class="name">
          ${itemEn.itemDescription}
        </span>
        <span class="price">
          ${itemEn.price}
        </span>
      </div>`;

    const group = document.getElementById(itemNl.groupName);
    const groupEn = document.getElementById(itemEn.groupName);
    const locale = document.getElementById('locale-name').innerText;

    if (group && document.getElementById(itemNl.itemId) === null) {
      const products = document.getElementsByName(itemNl.groupName + "-product");
      const sortNo = itemNl.itemSortNo - 1 >= products.length ? products.length - 1 : itemNl.itemSortNo - 1

      itemNl.itemSortNo - 1 >= products.length || !products[sortNo] ? products[sortNo].insertAdjacentElement('afterend', newMenuItem) : products[sortNo].parentElement.insertBefore(newMenuItem, products[sortNo]);
    } else if (!group && locale === 'nl') {
      const newSection = await fetchGroupInfo(itemEn.itemId, locale)
      const groups = document.getElementsByName("group");
      if (newSection["element"]) {
        if (groups.length === 0) {
          const main = document.getElementsByTagName('main')[0];
          main ? main.appendChild(newSection["element"]) : "";
        } else {
          const sortNo = newSection["sortNo"] - 1 >= groups.length ? groups.length - 1 : newSection["sortNo"] - 1
          newSection["sortNo"] - 1 >= groups.length ? groups[sortNo].parentElement.insertAdjacentElement('afterend', newSection["element"]) : groups[sortNo].parentElement.insertBefore(newSection["element"], groups[sortNo]);
        }
      }
    }

    if (groupEn && document.getElementById(itemEn.itemId) === null) {
      const products = document.getElementsByName(itemEn.groupName + "-product");
      const sortNo = itemEn.itemSortNo - 1 >= products.length ? products.length - 1 : itemEn.itemSortNo - 1
      itemEn.itemSortNo - 1 >= products.length ? products[sortNo].insertAdjacentElement('afterend', newMenuItemEn) : products[sortNo].parentElement.insertBefore(newMenuItemEn, products[sortNo]);
    } else if (!groupEn && locale === 'en') {
      const newSection = await fetchGroupInfo(itemEn.itemId, locale)
      const groups = document.getElementsByName("group");

      if (newSection["element"]) {
        if (groups.length === 0) {
          const main = document.getElementsByTagName('main')[0];
          main ? main.appendChild(newSection["element"]) : "";
        } else {
          const sortNo = newSection["sortNo"] - 1 >= groups.length ? groups.length - 1 : newSection["sortNo"] - 1
          newSection["sortNo"] - 1 >= groups.length ? groups[sortNo].parentElement.insertAdjacentElement('afterend', newSection["element"]) : groups[sortNo].parentElement.insertBefore(newSection["element"], groups[sortNo]);
        }
      }
    }
  }
}
