import * as MenuAction from "../menu-actions";
import consumer from "./consumer";

consumer.subscriptions.create("MenuChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const parsed = JSON.parse(data);
    switch (parsed["actionType"]) {
      case "REMOVE_MENU_ITEM":
        MenuAction.removeItem(parsed["itemId"], parsed["storeAbbreviation"]);
        break;
      case "ADD_MENU_ITEM":
        MenuAction.addItem(parsed["itemNl"], parsed["itemEn"], parsed["storeAbbreviation"]);
        break;
      default:
        break;
    }
    // Called when there's incoming data on the websocket for this channel
  },
});
