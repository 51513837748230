import "channels";

import * as ActiveStorage from "@rails/activestorage";
import Rails from "@rails/ujs";

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
Rails.start();
ActiveStorage.start();

export function toggleHamburger(hamburgerMenu, elementsToHide) {
  if (hamburgerMenu.classList.contains("d-none")) {
    hamburgerMenu.classList.remove("d-none");
    hamburgerMenu.classList.add("d-block");
    elementsToHide.forEach((element) => {
      element.classList.add("hidden");
    });
  } else {
    hamburgerMenu.classList.add("d-none");
    hamburgerMenu.classList.remove("d-block");
    elementsToHide.forEach((element) => {
      element.classList.remove("hidden");
    });
  }
}

export function calcViewport() {
  var vh = 0.01 * window.innerHeight,
    vw = 0.01 * window.innerWidth,
    viewHeight = document.documentElement.style.setProperty("--vh", "".concat(vh, "px")),
    viewWidth = document.documentElement.style.setProperty("--vw", "".concat(vw, "px"));

  if (window.innerWidth >= 770) {
    const elementsToUnhide = document.getElementsByName("hide-on-hamburger");
    const hamburgerMenu = document.getElementById("nav-hamburger");
    hamburgerMenu.classList.add("d-none");
    hamburgerMenu.classList.remove("d-block");
    elementsToUnhide.forEach((element) => {
      element.classList.remove("hidden");
    });
  }

  return { viewHeight, viewWidth };
}
